




import { Component } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";

@Component
export default class MainApp extends BaseView {}
