export enum HttpStatus {
  OK = 200,

  CREATED = 201,

  NO_CONTENT = 204,

  //400 : 사용자의 잘못된 요청을 처리할 수 없음
  BAD_REQUEST = 400,

  //401 : 인증되지 않은 유저가 인증이 필요한 페이지를 요청한경우
  UNAUTHORIZED = 401,

  //예약됨
  PAYMENT = 402,

  //402 : 접근 금지, 디렉터리 리스팅 요청 및 관리자 페이지 접근 등을 차단
  FORBIDDEN = 403,

  //없는 페이지
  NOT_FOUND = 404,

  GONE = 420,

  LOCKED = 423,

  UPGRADE_REQUIRED = 426,
}
