
import { Vue } from "vue-property-decorator";
import { AlertCallback } from "@/components/dialog/AlertDialog.vue";
import { AxiosError } from "axios";
import { BaseRepo } from "@/models/BaseRepo";
import { HttpStatus } from "@/consts/HttpStatus";

export default class BaseView extends Vue {
  protected isConnecting = false;

  public handleError(error: AxiosError, callback?: AlertCallback) {
    let msg = "";

    const code = error.response?.status ?? 0;

    if (code === HttpStatus.UNAUTHORIZED) {
      this.$alert("알림", "다시 로그인 해주세요..", this.goLogin);
      return;
    }

    if (error.response) {
      //서버응답은 발생 2xx범위의 응답이 아닐 경우
      const repo = error.response.data as BaseRepo;
      if (repo.statusText) {
        msg = repo.statusText;
      } else {
        msg = error.response.statusText;
      }
    } else if (error.request) {
      //요청완료, 응답이 없을 경우
      msg = "서버응답 없음";
    } else {
      //요청 설정 중 오류
      msg = "요청 설정 오류";
      console.error(error);
    }

    this.$alert("API 오류", msg, callback);
  }

  private goLogin() {
    this.$router.replace({ name: "login" });
  }
}
